/* Universal CSS */
* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: #000;
}
img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
h2.section-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
}
h4,
.accordion-button {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
}
h3,
.point-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}
.section-heading-about{
  
}
.bgImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.py-100 {
  padding-top: 160px;
  padding-bottom: 160px;
}
p.paragraph,
.accordion-body {
  font-size: 16px;
  line-height: 24px;
}
.section-title-about{
  font-weight: 500 !important;
}
.blue-text {
  color: #044b82;
  font-weight: 600;
}
a {
  text-decoration: none;
}
.pageTitle,
.page-title {
  font-weight: 700;
}
.blueBg {
  background: #63a4d7;
}
button.mybtns,
.form-Data-Wrapper button[type="submit"] {
  background: #044b82;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #fff;
  padding: 8px 10px;
  border: none;
}
.bg-none {
  background: none;
}
.border-none {
  border: none;
}
:focus-visible {
  outline: none;
}
/* Header CSS */
.bg-dark {
  background-color: #000 !important;
}
button.header-btns {
  background: none;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 5px 15px;
}
button.bg-btn {
  background-color: #fff;
  color: #000;
  margin-left: 30px;
}
li.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 75px;
}
button.bg-none-btn:hover {
  background: #fff;
  color: #000;
}
button.bg-btn:hover {
  background: transparent;
  color: #fff;
}
li.nav-item a.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li.nav-item a.nav-link > span {
  margin-left: 10px;
}
button.navbar-toggler {
  background-color: #fff;
}
.collapse:not(.show) {
  transition: all 5s;
}
.navbar-collapse.show {
  transition: all 5s;
}
ul.navbar-nav {
  transition: all 5s;
}

/* Footer CSS */
.footer-social-icons-wrapper img {
  width: 32px;
  margin-right: 30px;
}
.footer-logo-wrapper img {
  max-width: 230px;
}
h6.footer-menu-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 30px;
  color: #fff;
}
h6.footer-menu {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #fff;
}
.footer-menu-wrpper a {
  text-decoration: none;
  color: #fff;
}
input.footer-emailBox {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
}
.lang-icon{
  width: 100%;
}
/* Media Query */

@media (max-width: 1440px) {
  li.nav-item {
    margin-right: 40px;
  }
}

@media (max-width: 1366px) {
  .footer-social-icons-wrapper img {
    width: 30px;
    margin-right: 20px;
  }
}
@media (max-width: 768px) {
  
  ul.navbar-nav {
    position: absolute;
    width: 100%;
    background: #000;
    left: 0;
    padding: 30px 0px;
    transition: all 5s;
  }

  li.header-btns {
    justify-content: center;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  h2.section-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }
}
@media (max-width: 375px) {
  h2.section-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }

  h3,
  .point-tile {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  h4,
  .accordion-button {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }
}
