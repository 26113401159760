.blogBannerImg {
  background-image: url("../../Images/Blog/blogBanner.jpg");
  padding: 150px 0;
}
p.blog-publishDate {
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 768px) {
}
