.safetyImg {
  background-image: url("../../Images/Safety/safetyImg.jpg");
  padding: 200px 0;
}
.imgBox-wrapper img {
  width: 130px;
}
h4.imgBox-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
section.safety-points {
  background: #63a4d7;
  margin-bottom: 100px;
}
