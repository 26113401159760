section.signupFormWrapper .form-wrapper-column-1 {
  width: 55%;
}
section.signupFormWrapper .form-wrapper-column-2 {
  width: 43%;
}
.imgWrapper {
  background-image: url("../../Images/SignupForm/formBanner.jpg");
  padding: 200px 0;
}
.outer-div-delete-account{
     padding: 80px;
}
.signupTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.form-Data-Wrapper .form-control {
  border: 1px solid #555555;
  border-radius: 10px;

  padding: 8px 23px;
}
.form-Data-Wrapper .form-control::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.form-Data-Wrapper .input-group-text,
.form-Data-Wrapper input.form-check-input {
  border: 1px solid #555555;
}
.form-Data-Wrapper .form-control,
.form-Data-Wrapper label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.form-Data-Wrapper button[type="submit"] {
  /* background: #044b82;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; */
  padding: 8px 30px;
  /* color: #fff;
  border-color: #044b82; */
}
.form-Data-Wrapper .form-check-input:checked {
  background-color: #044b82;
  border-color: #044b82;
}
section.frgt-pswd-wrapper .form-Data-Wrapper {
  max-width: 500px;
  margin: auto;
}
.form-Data-Wrapper .input-group-text img {
  width: 28px;
}

/* -----------------Forget Password Page-------------------------- */
.forget-pswd-text {
  cursor: pointer;
}
.forget-img-wrapper img {
  width: 400px;
}

@media (max-width: 768px) {
  section.signupFormWrapper > div {
    width: 100% !important;
  }
  .forget-img-wrapper img {
    width: 350px;
  }
}
