.signupBtns-warpper button {
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-top: 20px;
}
button.bgBtn {
  background: #000000;
  border: 1px solid #000;
  color: #ffffff;
}
button.bgBtn:hover {
  background: #fff;
  border: 1px solid #000;
  color: #000;
}
button.bgNoneBtn {
  border: 1px solid #044b82;
}
button.bgNoneBtn:hover {
  background: #044b82;
  color: #fff;
}
.Online-Store-wrapper img {
  width: 130px;
}
.homeBanner-img-wrapper {
  background-image: url("../../Images/Homepage/home-banner.webp");
  border-radius: 10px;
}

section.investEarn {
  background-image: url("../../Images/Homepage/Invest-Earn-Bg.png");
}
section.estimate {
  background: #63a4d7;
}
.estimate-form-wrapper input,
.estimate-form-wrapper input::placeholder {
  background: #ffffff;
  border-radius: 10px;
  border: none;
  padding: 10px 11px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;
}
button.getBtn {
  background: #044b82;
  border-radius: 10px;
  padding: 8px 14px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  color: #fff;
}
.applyDrive-img-wrapper {
  background-image: url("../../Images/Homepage/applyDriveBg.webp");
  border-radius: 10px;
}
section.applyDrive {
  background: #eaf6ff;
}
/* button.applyDrive-Btn {
  background: #044b82;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  padding: 8px 10px;
  border: none;
} */

@media (max-width: 768px) {
  .homeBanner-img-wrapper,
  .applyDrive-img-wrapper {
    height: 350px !important;
  }
}
